*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
}

body {
	margin: 0;
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #fff;
	--color-link-hover: rgba(198,55,160,1);
	--color-bg-alt: #000;
	--color-text-alt: #fff;
	color: var(--color-text);
	background-color: var(--color-bg);
	background-image: linear-gradient( 110.1deg,  rgba(30,2,83,1) 44.2%, rgba(198,55,160,1) 138.2% );
    font-family: 'Roboto', sans-serif;
	font-weight: 300;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}
strong {
	font-weight: 400;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.grid {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(8,1fr);
}

.grid__item {
	position: relative;
	will-change: transform;
	grid-column: var(--c);
	grid-row: var(--r);
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: auto;
	aspect-ratio: 1;
	background-size: cover;
	background-position: 50% 50%;
	will-change: transform, opacity;
}

.demo-1 .grid__item-img {
	filter: contrast(70%);
}

.cover {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	text-align: center;
}

.cover__title {
	font-size: 8vw;
	font-weight: 400;
	margin: 0;
	width: 100%;
}

.cover__title sup {
	font-size: 4vw;
	font-weight: bold;
	vertical-align: 75%;
}

.cover__subtitle {
	font-size: 1.5rem;
	font-weight: 400;
	margin: 0;
}

.footer {
	color: var(--color-text-alt);
	background: var(--color-bg-alt);
	height: 100vh;
	font-size: 3rem;
	line-height: 1.2;
	position: relative;
	z-index: 200;
	text-transform: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.footer p {
	max-width: 40ch;
	text-align: left;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0,0,0,0) !important;
	white-space: nowrap !important;
	border: 0 !important;
  }

  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
	position: absolute !important;
  }